import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import blog1 from "assets/img/blog1.png";
import blog2 from "assets/img/blog2.png";
import blog3 from "assets/img/blog3.png";
import blog4 from "assets/img/blog4.png";
import blog5 from "assets/img/blog5.png";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <h3 className={classes.title}>Love</h3>
          <p>
            Without love, dating and relationships are not worth the effort. It
            is love that adds depth and meaning to these experiences. In fact,
            the absence of love can make dating even more challenging. When you
            lack love, women can sense the hollowness in your words and actions,
            and it becomes difficult to find genuine success.
          </p>
          <p>
            In the realm of dating and relationships, there exist two forms of
            love: the love that appreciates and the love that gives. The love
            that appreciates is what most people think of when they say, &quot;I
            love you.&quot; It entails cherishing and admiring various aspects
            of a person, both physical and mental. It might encompass admiration
            for her virtue, her appearance, her sense of humor, or other
            qualities that draw you towards her.
          </p>
          <p>
            This form of love, though pleasant, is inherently self-interested.
            It revolves around what you appreciate and desire from the other
            person. You enjoy spending time with her because of certain
            qualities she possesses. Likewise, she may feel the same way about
            you. A connection is formed when both individuals recognize and
            value specific qualities in each other.
          </p>
          <p>
            Visualize this connection as a mutual approval of qualities present
            in one another. It is through this shared appreciation that a bond
            is established, creating a strong attraction and connection between
            two individuals.
          </p>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <img src={blog1} alt="..." className={imgClasses} />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <p>
              For the connection to continue and thrive, both people’s love for
              each other should be roughly equal.
            </p>
            <p>
              Obviously, it won’t immediately be an “omg I’m in love with you”
              level of love at first, but I’ll still use the word love, because
              it’s still the same feeling, just not quite strong enough to say
              “I love you.”
            </p>
            <p>
              If the girl loves you and your qualities at like a 6, and you love
              her at an 8, eventually this difference will become noticeable and
              she will feel that you love her more than she loves you.
            </p>
            <p>
              This leads to the death of attraction, because she knows she is
              getting the lesser end of the deal.
            </p>
            <p>
              And no one can blame her. If she has made herself into a fun,
              charismatic, intelligent, and virtuous woman, she needs to find
              someone who will offer her either those same characteristics or
              different characteristics that match the value of hers to make the
              trade worthwhile.
            </p>
            <p>
              In some “formulas” that people craft to explain attraction, this
              love is oftentimes called “value,” but I call it “love,” because
              it’s an all-encompassing admiration of any value.
            </p>
            <p>
              Different values - like popularity, charisma, wealth, skill at an
              art – can be important to one person, but less important to
              another person.
            </p>
            <p>
              One girl can love your sense of humor and it draws her to you;
              another girl can appreciate that sense of humor, but because
              you’re not very smart or accomplished, she won’t value your sense
              of humor as highly as the first girl does, because her value
              system is different.
            </p>
            <p>
              Therefore, saying “value” can be a bit vague. Instead, if you look
              at her feelings through her behavior towards you, you can know if
              she values you or not.
            </p>
            <p>You love what and how she is. She loves what and how you are.</p>
            <p>
              But I chose “love” for another, more important reason than
              explaining just the mechanism of attraction.
            </p>
            <p>
              I chose love to also include the second type of love: the love
              that gives.
            </p>
            <p>This love is selfless. It only wants to help.</p>
            <p>
              It is like the love a mother has for her child. She sacrifices her
              health, her time, her energy, and sometimes her life for her
              child.
            </p>
            <p>
              I don’t advise you actually sacrifice yourself for women,
              especially those who will not appreciate it, but I do advise you
              try to generate an unconditional love towards all women, even
              those who have done you wrong.
            </p>
            <p>
              This also does not mean you allow evil women into your life or put
              up with shitty behavior. On the contrary, one who truly loves all
              women would not allow a woman to abuse him without consequence,
              because he knows that this will only reward her bad behavior, and
              because he loves her, he will teach her virtue by either putting
              her in her place, and enforcing the boundary she has crossed with
              firm words, or by cutting her off from his life and never speaking
              to her again.
            </p>
            <p>
              This love isn’t always expressed in words. But it lies there in
              your heart, unmoved and undisturbed by anything at all.
            </p>
            <p>
              It is often expressed in action or even by inaction, by choosing
              NOT to do things that are immoral.
            </p>
            <p>
              This love means you will never, under any circumstance, sleep with
              or pursue a woman who has a boyfriend or husband. I have done this
              in the past and learnt my lesson. Do not do it, because it
              demonstrates a lack of love for her, because you draw her into
              immorality.
            </p>
            <p>Yes, it’s her choice to do it, but you help.</p>
            <p>
              The driver of the getaway car of a bank robbery goes to jail, too.
            </p>
            <p>
              This love also means you will never betray a woman whom you have
              sworn loyalty to. If you want to sleep with other women, then she
              needs to know and agree to your desires.
            </p>
            <p>
              This love guarantees you will never deliberately lie to a woman,
              for any reason, even if you consider the ends to justify the
              means. You cannot always guarantee that she will not create false
              expectations in her heart, as is common during the initial dating
              process, but you will do your best to keep expectations clear.
            </p>
            <p>
              This love also means you will do your best to take care of her and
              add to her life in whatever way you can. Sometimes this love can
              lead to our loss if we give money, time, and energy towards a
              woman who does not appreciate it, but that is a lesson you will
              have to learn through trial and error.
            </p>
            <p>
              At its heart, this love is simply that you want her to be happy.
            </p>
            <p>You want her to find love, even if it is not with you.</p>
            <p>
              You want her to find fulfillment in her profession and hobbies.
            </p>
            <p>
              You want her to be healthy, long-lived, and to live a life worth
              living.
            </p>
            <p>You want her to be good, kind, and generous.</p>
            <p>
              Think of this love as you giving a gift to her, and her giving a
              gift to you.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <img src={blog2} alt="..." className={imgClasses} />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <p>
              This love is purposefully cultivated, as if it is a suit of armor
              that one smiths in a forge. In place of steel, the materials that
              make up this armor are thoughts and feelings. We intentionally
              fabricate these thoughts of well-wishing for her and all women
              (and all people) in our minds and hearts.
            </p>
            <p>
              This love that gives most difficult part of all of dating and
              relationships, and requires tremendous effort and practice, but it
              is the most rewarding.
            </p>
            <p>
              If you can muster the strength to create this love despite all
              your traumas and heartbreaks, you will be an unstoppable force who
              will have zero issue finding the woman or women of his dreams and
              bringing her into his life.
            </p>
            <p>
              This love can also lead you to the ending of all suffering, but
              that is a topic for another day.
            </p>
            <p>
              I’m not a monk; I’m a horny guy who learned how to satisfy his
              horniness and do some good with it on occasion.
            </p>
            <p>Speaking of horniness...</p>
          </GridItem>
          {/* <Quote
						textClassName={classes.quoteText}
						text="“Unconditional love for women will be the foundation of almost everything you do. Without it, you can find success with women, but it will be hollow.”"
						author="Hector Castillo"
					/> */}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <h3 className={classes.title}>Lust</h3>
          <p>
            Without lust, all of this is just friendship. This is the singularly
            unique quality in this formula that differentiates a romantic and
            sexual relationship from that of a relationship with friends and
            family.
          </p>
          <p>
            You cannot have a relationship without it. If you do, then it’s not
            a relationship with a woman, it’s a relationship with a person who
            happens to be a woman.
          </p>
          <p>
            Lust is powerful. I might even argue that it is the most powerful
            motivator in the entire universe.
          </p>
          <p>
            It would not be an understatement to say that our entire
            civilization was built by lust, built for lust, and sustained by
            lust.
          </p>
          <p>
            There are those who try to deny the power of lust and try to create
            relationships without, but this will always end in failure. This
            type of relationship can only be achieved by those who have cut off
            the root of lust and overcome it for all eternity, not those who
            suppress or repress it. Doing so will only result in lust coming
            back to the surface with a vengeance and expressing itself in
            unhealthy ways.
          </p>
          <p>Regardless, most of you are not monks pursuing the end of lust.</p>
          <p>We’re pursuing wholesome sexual and romantic relationships.</p>
          <p>So it’s important we understand the power and utility of lust.</p>
          <p>
            If a woman doesn’t have lust for you, the relationship will not work
            out so well. Lust is one of the reasons why women chase after men
            who are not good for them and deny men who are kinder and gentler.
            They know that the kind man will provide stability, but that
            stability without sexuality isn’t a relationship, it’s a friendship.
            Thus, they give into the demands of their lust and it leads them
            into dangerous relationships.
          </p>
          <p>
            We aim to give women no reason to chase after unhealthy
            relationships and provide them with a nice, but lustful partnership.
            To do that, we must acknowledge lust by tapping into it and invoking
            it to serve us.
          </p>
          <p>
            To put it bluntly, we need to make her pussy wet with the thought of
            our cocks stretching her out and filling her up.
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img src={blog3} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <p>
            If this crass language offends you, you’re not prepared to have a
            mature, sexual relationship with a woman, because you can’t even
            handle vulgar language.
          </p>
          <p>
            How will you handle the harsher realities, like learning that women
            reject you and choose other men because it’s what their pussy tells
            them to do?
          </p>
          <p>
            Don’t get me wrong, women do sometimes end up with men who don’t
            bring out the lust within them, but it is never a first choice that
            they happily make. It’s always a choice they regrettably make, and
            one they often make after they have already satisfied their lustful
            tendencies with other men before settling down.
          </p>
          <p>
            Do you want to be a man who is a second, third, fourth, or fifteenth
            choice?
          </p>
          <p>Do you want to be a man who is “good enough”?</p>
          <p>
            Or will you acknowledge the power of lust and use it to your
            advantage to bring the women you want into your life?
          </p>
          <p>It is lust that makes you want a woman in the first place.</p>
          <p>
            There are men who do not see the power of lust and think that they
            want women in their life for reasons of love.
          </p>
          <p>
            These are blind men. These are foolish men. These are stupid men.
          </p>
          <p>
            These are the poets, artists, musicians, and actors of history who
            have waxed philosophical about their supposed love for women with
            fancy words and sounds – mostly all of them liars and cowards.
          </p>
          <p>
            They were too cowardly and deceitful to simply admit that they want
            to stick their dick into every hole she has and say it bluntly.
          </p>
          <p>
            Sure, love helps reinforce and bolster our desire for someone as a
            partner, but lust is what gets the game started.
          </p>
          <p>
            They are mesmerized by her luscious lips, her supple ass, her
            beautiful face, her soft skin, her luxurious hair, her perky tits,
            and her soothing voice.
          </p>
          <p>
            And the soft, wet, stretchy cunt that can be abused for hours and
            give him endless pleasure.
          </p>
          <p>Don’t forget, either, how much she desires your cock.</p>
          <p>
            So much of our culture and society revolves around disguising not
            only the lust of men but also the lust of women.
          </p>
          <p>
            Sometimes we are told that men are pigs and love sex, and the
            conversation ends there.
          </p>
          <p>Oh no, my friend. How mistaken you are.</p>
          <p>Women’s lust knows even fewer bounds than male lust.</p>
          <p>When there’s fucking, who is louder – the man or woman?</p>
          <p>Who can usually go for more rounds – the man or woman?</p>
          <p>Who feels more pleasure from orgasm – the man or woman?</p>
          <p>
            Who has more clothing and accessories designed simply for looking as
            sexually appealing as possible?
          </p>
          <p>Women.</p>
          <p>Women love sex more than men. Women lust harder than men.</p>
          <p>
            If you don’t know this or even struggle to believe it, you still
            have much to learn.
          </p>
          <p>
            But once you do learn this or at least suspend your disbelief and
            trust me, then you will see clearly how important it is for you to
            become a sexy man.
          </p>
          <p>
            You must elicit lust from her and she must elicit lust from you, but
            the second part is usually quite automatic, as the charms and
            appearances of women are enough for us to lose our fucking minds.
          </p>
          <p>Your main priority should be to make yourself sexier.</p>
          <p>
            Whatever the physical attributes you were born with, you’re stuck
            with those.
          </p>
          <p>
            But what you can change is how you dress your meatsuit. You can also
            change its shape with fitness and diet.
          </p>
          <p>
            How do you walk? How do you speak with your voice? How do you speak
            with your eyes? How do you gesture with your hands and draw your
            words in the air as you talk?
          </p>
          <p>
            And if you draw closer to her, how do you touch her? Where do you
            touch her? Do you touch her for too long, not long enough, or just
            right?
          </p>
          <p>
            Lust is a feeling you draw out of her with your appearance and your
            behavior.
          </p>
          <p>Lust can come from the most uncommon places.</p>
          <p>
            It can be the way you run your hands through your hair as you work
            on a project. She watches you and is mesmerized by the way you are
            and her pussy tingles with excitement because you’re sexy.
          </p>
          <p>As with love, you must cultivate and build your sexiness.</p>
          <p>
            It is quite closely tied with the first love that admires, except
            because you’re a man and she’s a woman, a natural lust is generated.
          </p>
          <p>
            When you admire a quality in another man, you wish for him to be
            your friend.
          </p>
          <p>
            When a woman admires that same quality in another man, it can turn
            into lust.
          </p>
          <p>However, this is not the end of the skill of lust.</p>
          <p>
            Lust can be purposefully created. If you recognize and admit your
            lust for pussy, and you move through the world while focused on your
            horniness for women, these thoughts and feelings will naturally
            imbue your actions and words with sexiness.
          </p>
          <p>With practice, you will become sexy.</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <h3 className={classes.title}>Respect</h3>
          <p>
            Love and lust are crucial to attraction, but without respect, they
            will not have any limitations, and we will be like animals or
            psychopathic gods of pleasure.
          </p>
          <p>
            You should think of respect in two ways: the respect of boundaries
            and the respect of her values and desires.
          </p>
          <p>
            How I envision the respect of boundaries is best demonstrated
            through the act of bowing to one another.
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img src={blog4} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <p>
            We both acknowledge each other’s right to be. We acknowledge each
            other’s values and beliefs. And we respect each other’s desires.
          </p>
          <p>
            We don’t have to agree with them, but we do respect that they are
            what they are.
          </p>
          <p>
            We may even ask for change or for compromise, but we must first
            respect.
          </p>
          <p>
            This respect is what allows you to tell her your beliefs and desires
            without her dismissing them. The same holds for her telling you what
            she wants and believes and not dismissing her outright.
          </p>
          <p>
            These desires can be about what we want from each other, what we
            want from our job, from our religion, or even what we want to eat
            for dinner tonight.
          </p>
          <p>
            We respect that she is a being who has a right to live and choose
            her own destiny and we also ask that she respects us the same.
          </p>
          <p>
            However, once that respect is breached, whether intentionally or
            unintentionally, that is when the respect of boundaries is applied.
          </p>
          <p>
            We can think of boundaries as a line we draw on the ground, telling
            the other person how far we allow them to enter our space.
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img src={blog5} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <p>
            Enforcing these boundaries is when we must be firm with others and
            tell them we don’t appreciate this line or that line being crossed.
          </p>
          <p>
            We must always be on the lookout for the boundaries of women, both
            physical and verbal, and also be vigilant to ensure that our
            boundaries are not crossed, either.
          </p>
          <p>
            By doing this, women will instantly recognize you as a man worth
            being around because she feels safe. This feeling of safety turns
            into trust. This trust turns into comfort.
          </p>
          <p>
            When a woman feels safe, trusting, and comfortable around you, and
            there is also lust, then she will let you play with her body as if
            it is your personal fucktoy.
          </p>
          <p>
            If there is also love, then she will gladly give her body and mind
            to you for the rest of her life and be both your whore, your queen,
            and your best friend.
          </p>
          <p>
            And by enforcing your boundaries firmly but gently, she will gain
            respect for you. This respect itself will also transform into love
            and lust because a man who does not have boundaries and who does not
            enforce them is worthy of neither love, lust, nor respect.
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.section}>
          <h3 className={classes.title}>Love Lust Respect</h3>
          <p>
            If you practice these to a small degree, you will succeed in a small
            way.
          </p>
          <p>
            If you practice these to a medium degree, you will succeed in a
            medium way.
          </p>
          <p>
            If you practice these to a high degree, you will be highly
            successful in dating and relationships.
          </p>
          <p>It is up to you how good you are.</p>
          <p>In other words, git guud.</p>
          <p>Dating and relationships are a skill.</p>
          <p>
            There is no aspect of dating and relationships not covered by these
            three qualities of love, lust, and respect.
          </p>
          <p>
            Feel free to rename these qualities in a way that fits your
            personality.
          </p>
          <p>
            But this explanation is exhaustive for all dating and relationship
            woes. Master this, and you’ll almost never struggle with finding
            dates, getting your dick sucked, or having a girl want to spend the
            rest of her life with you.
          </p>
          <p>Love,</p>
          <p>Hector Castillo</p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
