/*eslint-disable*/ import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import logo from 'assets/img/logo.png';
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js';
import SectionProduct from './Sections/SectionProduct.js';
import SectionTeam from './Sections/SectionTeam.js';
import SectionTest from './Sections/SectionTest.js';

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Header
				color='transparent'
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor='info' />}
				fixed
				changeColorOnScroll={{
					height: 300,
					color: 'white',
				}}
				{...rest}
			/>
			<Parallax image={require('assets/img/Bloodborne2.jpg')} filter='dark'>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6}>
							<h1 className={classes.title}>Transform Your Dating Life</h1>
							<h4>
								Hey there, my name is Hector Castillo and I{"'"}m a dating and
								relationship coach. Whether you{"'"}re struggling to find the right
								person or you{"'"}re just looking to improve your dating skills, I{"'"}m
								here to help. My goal is to empower you with the knowledge and tools you
								need to create the love life you{"'"}ve always wanted. So if you{"'"}re
								ready to take control of your dating life and start living your best
								life, you{"'"}ve come to the right place. Let{"'"}s get started!
							</h4>
							<br />
							{/* <Button
								color='danger'
								size='lg'
								href='https://www.youtube.com/watch?v=GyNBP_5DxvY&ab_channel=HectorCastillo%3ADating%26Relationships'
								target='_blank'
							>
								<i className='fas fa-play' />
								Watch video
							</Button> */}
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<SectionTeam />
					<SectionProduct />
					<SectionTest />
				</div>
			</div>
			<Footer
				content={
					<div>
						<div className={classes.left}></div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a
								href='https://www.linkedin.com/in/sorin-gifei-frontend/'
								target='_blank'
							>
								Sorin Gifei
							</a>{' '}
							for a better web.
						</div>
					</div>
				}
			/>
		</div>
	);
}
