import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import SnackbarContent from "components/Snackbar/SnackbarContent";

import logo from "assets/img/logo.png";
import defaultAvatar from "assets/img/placeholder.jpg";

import Check from "@material-ui/icons/Check";
import AuthContext from "contexts/AuthContext";
import Footer from "components/Footer/Footer.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
// import { getFile } from "api/firebaseApi";
import Favorite from "@material-ui/icons/Favorite";

const useStyles = makeStyles(profilePageStyle);

export default function SuccessPage() {
  const { user } = useContext(AuthContext);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  // const downloadFile = async (product) => {
  //   const url = await getFile(product.fileUrl);
  //   window.open(url, "_blank");
  // };

  return (
    <div>
      <Header
        image={require("assets/img/logo.png")}
        brand={
          <img
            src={logo}
            alt="logo"
            style={{ width: "auto", height: "4rem" }}
          />
        }
        links={<HeaderLinks dropdownHoverColor="rose" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
        }}
      />
      <Parallax
        image={require("assets/img/examples/city.jpg")}
        filter="dark"
        className={classes.parallax}
      />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={defaultAvatar} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>{user?.email}</h3>
                  <h6>Student</h6>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <Card>
            <CardBody>
              <div
                className={classNames(classes.description, classes.textCenter)}
              >
                <br></br>
                <br></br>
                <p>
                  You&apos;ve taken a significant step towards improving your
                  dating skills and achieving your relationship goals.
                </p>
                <p>
                  Stay tuned for further instructions on accessing the program
                  materials and scheduling your sessions. In the meantime, get
                  ready to embark on a transformative journey that will enhance
                  your self-awareness, communication skills, and overall dating
                  success.
                </p>

                <p>
                  I&apos;m thrilled to be a part of your journey towards finding
                  love and happiness.
                </p>

                <br></br>
                <SnackbarContent
                  message={
                    <span>
                      <b>SUCCESS ALERT:</b> Congratulations on your successful
                      payment!
                    </span>
                  }
                  close
                  color="success"
                  icon={Check}
                />
                <br></br>
              </div>
            </CardBody>
          </Card>

          <Clearfix />
        </div>
      </div>
      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-login"
                    target="_blank"
                    className={classes.block}rel="noopener noreferrer"
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-login"
                    target="_blank"
                    className={classes.block}rel="noopener noreferrer"
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <a
                href="https://www.creative-tim.com?ref=mkpr-login"
                target="_blank"rel="noopener noreferrer"
              >
                Sorin Gifei
              </a>{" "}
              for a better web
            </div>
          </div>
        }
      />
    </div>
  );
}
