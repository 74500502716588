import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDRqrFqY1o9N-v63C6yW42NQmoRgcyRxXM",
  authDomain: "project-hector-6eaf1.firebaseapp.com",
  projectId: "project-hector-6eaf1",
  storageBucket: "project-hector-6eaf1.appspot.com",
  messagingSenderId: "647720117738",
  appId: "1:647720117738:web:0f3feba82c3e8dba687e93",
  measurementId: "G-1WEND8S3QK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

export { auth, db };
