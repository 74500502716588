import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const storage = getStorage();

const getUserDoc = async (id) => {
  if (!id) {
    return {};
  }
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

const auth = getAuth();

const sendResetEmail = function (email) {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch(() => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // ..
    });
};

const getFile = async (fileRef) => {
  // const gsReference = ref(storage, "gs://bucket/images/stars.jpg");

  const gsReference = ref(storage, fileRef);

  return getDownloadURL(gsReference)
    .then((url) => {
      return url;
      // Insert url into an <img> tag to "download"
    })
    .catch((error) => {
      // A full list of error codes is available at
      switch (error.code) {
        case "storage/object-not-found":
          // File doesn't exist
          break;
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;

        // ...

        case "storage/unknown":
          // Unknown error occurred, inspect the server response
          break;
        default:
          break;
      }
    });
};

export { getUserDoc, getFile, sendResetEmail };
