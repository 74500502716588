import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import workStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(workStyle);
const useStylesVideo = makeStyles((theme) => ({
	iframeContainer: {
		position: 'relative',
		width: '100%',
		height: '0',
		paddingBottom: '56.25%', // 16:9 aspect ratio (change this to fit your aspect ratio)
		'& iframe': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0',
		},
	},
}));

export default function SectionWork() {
	const classesVideos = useStylesVideo();
	const classes = useStyles();
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Please enter your name'),
			email: Yup.string()
				.email('Invalid email address')
				.required('Please enter your email'),
			message: Yup.string().required('Please enter your message'),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				const payload = {
					childKey: {
						name: values.name,
						email: values.email,
						message: values.message,
					},
				};

				window.grecaptcha.ready(async () => {
					const token = await window.grecaptcha.execute(
						process.env.REACT_APP_RECAPTCHA_SITE_KEY,
						{
							action: 'submit',
						}
					);

					const res = await axios
						.post(`${process.env.REACT_APP_API_URL}/sendContactEmail`, {
							token,
							...payload.childKey,
						})
						.catch(() => {
							toast.error('Message failed to send');
							return false;
						});
					if (res) toast.success('Message sent successfully!');
				});

				setShowSuccessMessage(true);
				resetForm();
			} catch (error) {
				console.error(error);
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<div className={classes.section}>
			<Toaster position='bottom-center' />
			<GridContainer justify='center'>
				<GridItem md={12} sm={12}>
					<Card plain pricing>
						<CardBody pricing>
							<div className={classesVideos.iframeContainer}>
								<iframe
									width='100%'
									height='720'
									src='https://www.youtube.com/embed/GyNBP_5DxvY'
									title='YouTube video player'
									frameborder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
									allowfullscreen
								></iframe>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem cs={12} sm={8} md={8}>
					<h2 className={classes.title}>Let{"'"}s Talk</h2>
					<h4 className={classes.description}>
						Use the form below to schedule a consultation call with me. I always do a
						consultation call before signing clients because different clients need
						different programs and it is a bit of an investment so I want to make sure
						You know how the program works and how much it costs. After you submit the
						information I will contact you and set up a call
					</h4>
					{showSuccessMessage ? (
						<div>
							<h4>Success! Your message has been sent.</h4>
						</div>
					) : (
						<form
							onSubmit={formik.handleSubmit}
							id='demo-form'
							action='?'
							method='POST'
						>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6}>
									<CustomInput
										labelText='Your Name'
										id='name'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: formik.values.name,
											onChange: formik.handleChange,
											onBlur: formik.handleBlur,
										}}
										error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={6}>
									<CustomInput
										labelText='Your Email'
										id='email'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: formik.values.email,
											onChange: formik.handleChange,
											onBlur: formik.handleBlur,
										}}
										error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</GridItem>
								<CustomInput
									labelText='Your Message'
									id='message'
									formControlProps={{
										fullWidth: true,
										className: classes.textArea,
									}}
									inputProps={{
										multiline: true,
										rows: 5,
										value: formik.values.message,
										onChange: formik.handleChange,
										onBlur: formik.handleBlur,
									}}
									error={
										Boolean(formik.touched.message) && Boolean(formik.errors.message)
									}
									helperText={formik.touched.message && formik.errors.message}
								/>
								<GridItem
									xs={12}
									sm={12}
									md={12}
									className={classes.customButtonWidth + ' ' + classes.mlAuto}
								>
									<br></br>
									<Button
										data-sitekey='6Ler840mAAAAAE9EagyASQizl47aumA-LmuR5QUa'
										data-callback='onSubmit'
										position='center'
										fullWidth
										color='youtube'
										type='submit'
										disabled={formik.isSubmitting}
									>
										Send Message
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					)}
				</GridItem>
			</GridContainer>
		</div>
	);
}
