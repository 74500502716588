import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Slide from '@material-ui/core/Slide';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/Footer.js';
import Button from 'components/CustomButtons/Button.js';
import Accordion from 'components/Accordion/Accordion.js';
import productStyle from 'assets/jss/material-kit-pro-react/views/productStyle.js';
import logo from 'assets/img/logo.png';
import product1 from 'assets/img/examples/product1.jpg';
import product2 from 'assets/img/examples/product2.jpg';
import product4 from 'assets/img/examples/product4.jpg';
import wsrm from 'assets/img/wsrm.jpg';
import { getAuth, getIdToken } from 'firebase/auth';
import axios from 'axios';
import typographyStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.js';
import toast, { Toaster } from 'react-hot-toast';
import Rodal from 'rodal';

const useStyles = makeStyles(productStyle);
const useStylesTypography = makeStyles(typographyStyle);
const styles = {
	footer: {
		flexShrink: 0,
		marginTop: 'auto',
	},
};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
  });
  
Transition.displayName = "Transition";


export default function ProductPage() {
	const classes = useStyles();
	const typographyClasses = useStylesTypography();
	const [modalVisible, setModalVisible] = React.useState(false);
	const [stripeElements, setStripeElements] = React.useState(null);
	const [stripe, setStripe] = React.useState(null);
	// const loadingToastIdRef = React.useRef(null);

	const history = useHistory();

	const showModal = () => {
		setModalVisible(true);
		// setStripeElements(null); // Reset stripeElements state
		// setStripe(null); // Reset stripe state
		toast.success('Payment Initialized! Please wait...');
	};

	const hideModal = () => {
		setModalVisible(false);
		toast.dismiss();
		// setStripeElements(null);
		// setStripe(null);
		toast.error('Payment cancelled.');
	};

	// const showLoadingToast = (message) => {
	// 	if (!loadingToastIdRef.current) {
	// 		loadingToastIdRef.current = toast.loading(message);
	// 	} else {
	// 		toast.update(loadingToastIdRef.current, { render: message });
	// 	}
	// };

	// const hideLoadingToast = () => {
	// 	if (loadingToastIdRef.current) {
	// 		toast.dismiss(loadingToastIdRef.current);
	// 		loadingToastIdRef.current = null;
	// 	}
	// };

	const handleKeyDown = React.useCallback((event) => {
		if (event.keyCode === 27) {
			// ESC key
			hideModal();
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const buyTest = async (productId) => {
		const auth = getAuth();
		const user = auth.currentUser;

		if (!user) {
			history.push('/login-page');
			return;
		}

		// showLoadingToast('Payment Initialized! Please wait...');

		getIdToken(user).then(async (idToken) => {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/initPayment`,
				{
					productId: productId,
				},
				{
					headers: {
						Authorization: `Bearer ${idToken}`,
					},
				}
			);

			showModal();

			const stripe = window.Stripe(
				process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
			);
			const elements = stripe.elements({
				appearance: { theme: 'stripe' },
				clientSecret: res.data.clientSecret,
			});
			const paymentElement = elements.create('payment');
			paymentElement.mount('#payment-element');

			setStripe(stripe);
			setStripeElements(elements);
		});
	};

	const pay = async (event) => {
		event.preventDefault();
		event.stopPropagation();

		const auth = getAuth();
		const user = auth.currentUser;

		// showLoadingToast('Processing payment...');

		const { error } = await stripe.confirmPayment({
			elements: stripeElements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: user.name,
						email: user.email,
					},
				},
			},
			redirect: 'if_required',
		});

		// hideLoadingToast();

		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				toast.error(error.message);
			} else {
				toast.error('Error processing payment.');
			}
		} else {
			hideModal();
			toast.success(
				'Payment successful, please check your email/account for confirmation.'
			);
		}
	};
	const images = {
		wsrm: {
			original: wsrm,
			thumbnail: wsrm,
		},
		product4: {
			original: product4,
			thumbnail: product4,
		},
		product1: {
			original: product1,
			thumbnail: product1,
		},
		product2: {
			original: product2,
			thumbnail: product2,
		},
	};
	return (
		<div className={classes.productPage}>
			<Toaster position='bottom-center' />
			<Rodal
				visible={modalVisible}
				closeMaskOnClick={false}
				onClose={() => hideModal()}
				borderRadius={10}
			>
				<div className={typographyClasses.typo}>
					<h3>Pay With Card</h3>
				</div>
				<div id='payment-element'></div>
				<div id='card-errors' className='mt-3 text-danger' role='alert'></div>

				<div
					style={{
						display: 'flex',
						marginTop: '10px',
						justifyContent: 'space-between',
					}}
				>
					<Button type='button' round onClick={() => hideModal()}>
						Close
					</Button>

					<Button type='button' color='rose' round onClick={pay}>
						Pay
					</Button>
				</div>
			</Rodal>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt='logo' style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor='rose' />}
				fixed
				color='transparent'
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/bg6.jpg')}
				filter='rose'
				className={classNames(classes.pageHeader, classes.parallaxFullHeight)}
			></Parallax>
			<div
				className={classNames(
					classes.section,
					classes.sectionGray,
					classes.content
				)}
			>
				<div className={classes.container}>
					<div
						className={classNames(
							classes.main,
							classes.mainRaised,
							classes.mainContainer
						)}
					>
						<GridContainer>
							<GridItem md={6} sm={6}>
								<img
									src={images.wsrm.original}
									alt='Product'
									style={{
										width: '100%',
									}}
								/>
							</GridItem>
							<GridItem md={6} sm={6}>
								<h2 className={classes.title}>What She Really Means</h2>
								<h3 className={classes.mainPrice}>$39.99</h3>
								<Accordion
									active={0}
									activeColor='rose'
									collapses={[
										{
											title: 'Tools and strategies you need to become a master of texting',
											content: (
												<p>
													Are you tired of sending text messages to women and getting no
													response? Do you find yourself struggling to keep the conversation
													going or coming up with interesting things to say? If so, then
													&quot;What She Really Means&quot; is the book for you. This guide
													is designed to teach you the secrets of successful texting, so you
													can finally get enthusiastic responses from the women you&apos;re
													interested in.
												</p>
											),
										},
										{
											title: 'How to create engaging conversations',
											content: (
												<p>
													You&apos;ll discover how to use the power of words to grab her
													attention and keep her interested in talking to you. From clever
													icebreakers to intriguing questions, you&apos;ll have a wide range
													of tools to help you make a great impression over text.
												</p>
											),
										},
										{
											title: 'How to ask her out and set up the date',
											content: (
												<p>
													Once you&apos;ve established a connection, you&apos;ll need to know
													how to take things to the next level. This book provides a
													step-by-step guide to asking her out and planning the perfect date
													that she won&apos;t want to miss.
												</p>
											),
										},
									]}
								/>
								<GridContainer className={classes.pullRight}>
									<Button
										round
										color='rose'
										onClick={() =>
											(window.location.href =
												'https://www.amazon.com/What-She-Really-Means-Texting-ebook/dp/B0C7HD6NX6/ref=sr_1_1?crid=2HL35EHGOZIOK&dib=eyJ2IjoiMSJ9.34eJ9559oZ4bpjkO98m2hQ.KdhNJURIav2PVLwiboyu08CXbU2z0z--FqiJmwANe4o&dib_tag=se&keywords=what+she+really+means+hector+castillo&qid=1713281203&s=books&sprefix=what+she+really%2Cstripbooks-intl-ship%2C183&sr=1-1')
										}
									>
										Buy Now <ShoppingCart />
									</Button>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<div className={classes.footer} style={styles.footer}>
				<Footer
					content={
						<div>
							<div className={classes.left}>
								<List className={classes.list}>
									<ListItem className={classes.inlineBlock}>
										<a href='/' className={classes.block} rel='noopener noreferrer'>
											About
										</a>
									</ListItem>
									<ListItem className={classes.inlineBlock}>
										<a href='/blog-post' className={classes.block}>
											My Philosophy
										</a>
									</ListItem>
								</List>
							</div>
							<div className={classes.right}>
								&copy; {1900 + new Date().getYear()} , made with{' '}
								<Favorite className={classes.icon} /> by{' '}
								<a
									href='https://www.linkedin.com/in/sorin-gifei-frontend'
									target='_blank'
									className={classes.aClasses}
									rel='noopener noreferrer'
								>
									Sorin Gifei
								</a>{' '}
								for a better web.
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
}
