import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
	const classes = useStyles();
	return (
		<div className={classes.section}>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={12}>
					<a href='/blog-post' style={{ textDecoration: 'none' }}>
						<h2 className={classes.title}>Who Am I and How Can I Help You?</h2>
						<h3 className={classes.description}>
							The best way to get to know me is to read
						</h3>
						<br></br>
						<Button color='danger' size='lg'>
							<LibraryBooks /> My Philosophy Page
						</Button>
					</a>
					<br></br>
				</GridItem>
			</GridContainer>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={4} md={4}>
						<a
							href='/product-page'
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							<InfoArea
								title='My content'
								description="If you're new here, I suggest checking out my content by visiting either my YouTube channel or my Instagram and getting familiar with how my dating philosophy turns into action with my advice."
								icon={PlayCircleFilledIcon}
								iconColor='info'
								vertical
							/>
						</a>
					</GridItem>
					<GridItem xs={12} sm={4} md={4}>
						<a
							href='/product-page2'
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							<InfoArea
								title='Courses'
								description='I have two courses currently available. One is my course that teaches you how to Meet Girls Everywhere and is the foundation of your dating success. I also have a texting book, What She Really Means, that teaches you how to text girls and turn those conversations into dates and relationships.'
								icon={ReceiptIcon}
								iconColor='danger'
								vertical
							/>
						</a>
					</GridItem>
					<GridItem xs={12} sm={4} md={4}>
						<a href='/pricing' style={{ textDecoration: 'none', color: 'inherit' }}>
							<InfoArea
								title='Personal Coaching'
								description='There is nothing that comes close to working 1-on-1 with me. It is by far the most effective method of improving your dating life. Go here to my coaching page to learn more about working with me.'
								icon={WhatsAppIcon}
								iconColor='success'
								vertical
							/>
						</a>
					</GridItem>
				</GridContainer>
			</div>
		</div>
	);
}
