import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import manandwomen from 'assets/img/man-with-women.jpg';
import manandwife from 'assets/img/man-and-wife.jpg';
import Badge from 'components/Badge/Badge';
import InfoArea from 'components/InfoArea/InfoArea';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import MoodIcon from '@material-ui/icons/Mood';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import projectsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js';
import PublicIcon from '@material-ui/icons/Public';
import SectionJavascript from 'views/ComponentsPage/Sections/SectionJavascript';
import Datetime from 'react-datetime';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import Mail from '@material-ui/icons/Mail';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { useEffect } from 'react';
import classNames from 'classnames';
import hc from 'assets/img/hectorClassroom.png';
import styles3 from 'assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js';
import javascriptStyles from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js';
import { useState } from 'react';
import axios from 'axios';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import Check from '@material-ui/icons/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const useStyles = makeStyles(projectsStyle);
const useStyles2 = makeStyles(javascriptStyles);
const useStyles3 = makeStyles(styles3);
const useStyles4 = makeStyles((theme) => ({
	subscribeButton: {
		marginTop: '20px',
	},
}));

export default function SectionTeam() {
	const [signupModal, setSignupModal] = React.useState(false);
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const classes = useStyles();
	const classes2 = useStyles2();
	const classes3 = useStyles3();
	const classes4 = useStyles4();
	useEffect(() => {
		const timer = setTimeout(() => {
			setSignupModal(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, []);

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		if (validateEmail(email)) {
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/addSubscriber`, {
					email,
				});
				setIsEmailValid(true);
				setIsSubmitted(true);
				setShowWarning(false);
			} catch (error) {
				console.log('Error in subscribing');
				setIsEmailValid(false);
				setShowWarning(true);
			}
		} else {
			setIsEmailValid(false);
			setShowWarning(true);
		}
	};

	return (
		<div className={classes.section}>
			<div className={classes.projects + ' ' + classes.projects4}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={
								classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
							}
						>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{/* <NewsletterModal
									signupModal={signupModal}
									setSignupModal={setSignupModal}
									classes2={classes}
									classes3={classesVideos}
									hc={hc}
								/> */}
								{/* BUTTON SIGNUP MODAL */}
								{/* <Button block round onClick={() => setSignupModal(true)}>
									<Assignment /> Newsletter
								</Button> */}
								{/* SIGNUP MODAL START */}
								<Dialog
									classes={{
										root: classes2.modalRoot,
										paper: classes2.modal + ' ' + classes2.modalSignup,
									}}
									open={signupModal}
									TransitionComponent={Transition}
									keepMounted
									onClose={() => setSignupModal(false)}
									aria-labelledby='signup-modal-slide-title'
									aria-describedby='signup-modal-slide-description'
								>
									<Card plain className={classes2.modalSignupCard}>
										<DialogTitle
											id='signup-modal-slide-title'
											disableTypography
											className={classes2.modalHeader}
										>
											<Button
												simple
												className={classes2.modalCloseButton}
												key='close'
												aria-label='Close'
												onClick={() => setSignupModal(false)}
												style={{ bottom: '20px' }}
											>
												{' '}
												<Close className={classes2.modalClose} />
											</Button>
										</DialogTitle>
										<DialogContent
											id='signup-modal-slide-description'
											className={classes2.modalBody}
										>
											<div
												className={classNames(
													classes3.subscribeLine,
													classes3.subscribeLineImage
												)}
												style={{ backgroundImage: `url(${hc})` }}
											>
												<div className={classes3.container}>
													<GridContainer>
														<GridItem
															xs={12}
															sm={6}
															md={6}
															className={classNames(classes3.mlAuto, classes3.mrAuto)}
														>
															<div className={classes3.textCenter}>
																<h3 className={classes3.title}>Stay Connected with Me</h3>
																<p className={classes3.description}>
																	Subscribe to my newsletter for the latest tips and advice on
																	finding and nurturing love. I promise no spam, just valuable
																	insights to help you on your dating journey.
																</p>
															</div>
															<Card raised className={classes3.card}>
																<CardBody className={classes3.cardBody}>
																	<form onSubmit={onFormSubmit}>
																		<GridContainer>
																			{!isSubmitted && (
																				<>
																					<GridItem xs={12} sm={6} md={6} lg={8}>
																						<CustomInput
																							id='emailPreFooter'
																							formControlProps={{
																								fullWidth: true,
																								className: classes.cardForm,
																							}}
																							inputProps={{
																								startAdornment: (
																									<InputAdornment position='start'>
																										<Mail />
																									</InputAdornment>
																								),
																								placeholder: 'Your Email...',
																								value: email,
																								onChange: (e) => setEmail(e.target.value),
																							}}
																						/>
																					</GridItem>
																					<GridItem xs={12} sm={6} md={6} lg={8}>
																						<Button
																							color='primary'
																							block
																							className={classes4.subscribeButton}
																							type='submit'
																						>
																							Subscribe
																						</Button>
																					</GridItem>
																				</>
																			)}
																			{isSubmitted && isEmailValid && (
																				<SnackbarContent
																					message={
																						<span>
																							<b>SUCCESS ALERT:</b> Thank you for subscribing! Check
																							your email for further instructions.
																						</span>
																					}
																					close
																					color='success'
																					icon={Check}
																				/>
																			)}
																			{showWarning && (
																				<SnackbarContent
																					message={
																						<span>
																							<b>WARNING ALERT:</b> Please enter a valid email address.
																						</span>
																					}
																					close
																					color='danger'
																					icon='info_outline'
																				/>
																			)}
																		</GridContainer>
																	</form>
																</CardBody>
															</Card>
														</GridItem>
													</GridContainer>
												</div>
											</div>
										</DialogContent>
									</Card>
								</Dialog>
								{/* SIGNUP MODAL END */}
							</GridItem>
							<h2 className={classes.title}>How Can I Help You?</h2>
							<h5 className={classes.description}>
								Whether you want to improve your social skills to meet more girls or
								you&apos;re looking for a long-term relationship, I can help you achieve
								your goals. Here&apos;s how:
							</h5>
							<div className={classes.sectionSpace} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${manandwomen})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
									width: '100%',
								}}
							>
								<CardBody background className={classes.cardBody4}></CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<InfoArea
								className={classes.info4}
								title='Boost Your Self-Esteem'
								description='
								As you practice, you will become confident in your skills. No bullshit feel-good nonsense here. Confidence only comes from practice.'
								icon={MoodIcon}
								iconColor='info'
							/>
							<InfoArea
								className={classes.info4}
								title='Enhanced Social Skills'
								description='The same skill that get you friends are the skills that get you girls. Your entire social life will blossom.'
								icon={AccessibilityNewIcon}
								iconColor='primary'
							/>
							<InfoArea
								className={classes.info4}
								title='Want lots of girls?'
								description="
								Then get to work. I teach you principles that I've learned from my own successes and failures, as well as those I know and those I've taught."
								icon={SupervisedUserCircleIcon}
								iconColor='danger'
							/>
						</GridItem>
					</GridContainer>
					<hr />
					<GridContainer>
						<GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
							<InfoArea
								className={classes.info4}
								title='Find your ideal partner'
								description="Instead of lots of girls, you want that one special girl? Then let's fine her for you"
								icon={FavoriteIcon}
								iconColor='rose'
							/>
							<InfoArea
								className={classes.info4}
								title='Become worthy of her'
								description='If you want a high-quality woman, you must be a high-quality man worthy of her. This has little to do with your net-worth, achievements, or looks. It has to do with your character and your seductive skills'
								icon={StarHalfIcon}
								iconColor='success'
							/>
							<InfoArea
								className={classes.info4}
								title='Find the diamond in the dirt'
								description='There are many beautiful, amazing women out there, but you need to learn what objectively makes a good girlfriend/wife. Not everything that shines in the dirt is a diamond'
								icon={PublicIcon}
								iconColor='info'
							/>
						</GridItem>
						<GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
							<Card
								// background
								className={classes.card4}
								style={{
									backgroundImage: `url(${manandwife})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									height: '100%',
								}}
							>
								<CardBody
									// background
									className={classes.cardBody4}
								>
									{/* <Badge color="rose">Find Your Perfect Partner</Badge> */}
									{/* <a onClick={(e) => e.preventDefault}>
										<h3 className={classes.cardTitle}>Improved Relationship Skills</h3>
										<p className={classes.cardDescription}>
											With my coaching, you{"'"}ll learn how to approach and communicate
											effectively with potential partners. You{"'"}
											ll gain the skills necessary to attract the partner you desire and
											build a meaningful relationship.
										</p>
									</a> */}
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
