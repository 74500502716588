import React from 'react';
import Carousel from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import FormatQuote from '@material-ui/icons/FormatQuote';
import Star from '@material-ui/icons/Star';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import Warning from 'components/Typography/Warning.js';
import marc from 'assets/img/faces/marc.jpg';
import testimonialsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js';
import cardProfile1Square from 'assets/img/faces/card-profile1-square.jpg';
import cardProfile2Square from 'assets/img/faces/michael.png';
import josue from 'assets/img/faces/card-profile2-square.jpg';
import cardProfile4Square from 'assets/img/faces/benjamin.png';
import christian from 'assets/img/faces/christian.jpg';
import Button from 'components/CustomButtons/Button.js';
import useStylesVideo from '../../PricingPage/Sections/SectionPricing';

const useStyles = makeStyles(testimonialsStyle);

const SectionTest = () => {
	const classesVideos = useStylesVideo();
	const classes = useStyles();
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		color: 'primary',
	};
	return (
		<div className='cd-section'>
			{/* Testimonials 1 START */}
			<div
				className={classes.testimonials}
				// style={{ backgroundImage: `url(${simp})` }}
			>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={6}
							md={6}
							className={
								classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
							}
						>
							<h2 className={classes.title}>Testimonials</h2>
							<h5 className={classes.description}>
								The pictures may be stock photos, but their testimony is very real. This
								is but a fraction of the men I've helped.
							</h5>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={4} md={4}>
							<Card testimonial className={classes.card1}>
								<div className={classes.icon}>
									<FormatQuote />
								</div>
								<CardBody>
									<h5 className={classes.cardDescription}>
										{'"'}Hector{"'"}s coaching helped me improve approaching beautiful
										women, from what to say, dress, eye contact, to persistence, texting,
										and staying sharp. He{"'"}s a talented coach who cares about his
										students and shares insightful commentary on his Instagram feed. His
										videos and writing cover everything about women, dating, and life.
										Highly recommend him!{'"'}
									</h5>
								</CardBody>
								<CardFooter testimonial>
									<h4 className={classes.cardTitle}>Benjamin</h4>
									<h6 className={classes.cardCategory}>@BENJAMINM</h6>
									<CardAvatar testimonial testimonialFooter>
										<a href='#pablo' onClick={(e) => e.preventDefault()}>
											<img src={cardProfile4Square} alt='...' />
										</a>
									</CardAvatar>
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Card testimonial className={classes.card1}>
								<div className={classes.icon}>
									<FormatQuote />
								</div>
								<CardBody>
									<h5 className={classes.cardDescription}>
										{'"'}Hector helped me overcome a breakup slump and negative thoughts
										about girls. With coaching, we identified key fixes to my dating game.
										Hector suggested I become more passionate, leading to better
										connections with women. Coaching helped me break through walls and
										improve faster than I would have on my own. Thanks to Hector, I not
										only broke my dry spell but also improved my dating game beyond my
										expectations.{'"'}
									</h5>
								</CardBody>
								<CardFooter testimonial>
									<h4 className={classes.cardTitle}>Tahir</h4>
									<h6 className={classes.cardCategory}>@TAHIRK</h6>
									<CardAvatar testimonial testimonialFooter>
										<a href='#pablo' onClick={(e) => e.preventDefault()}>
											<img src={cardProfile1Square} alt='...' />
										</a>
									</CardAvatar>
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Card testimonial className={classes.card1}>
								<div className={classes.icon}>
									<FormatQuote />
								</div>
								<CardBody>
									<h5 className={classes.cardDescription}>
										{'"'}Hector{"'"}s coaching was a great decision. Before, I was trying
										to learn seduction on my own and wasn&apos;t as successful. After just
										hours of talking with him, I had a memorable lay and several new
										partners. He provided the missing information, technique, and passion
										to improve. His social intuition is unmatched and the lessons stick
										with me even years later.{'"'}
									</h5>
								</CardBody>
								<CardFooter testimonial>
									<h4 className={classes.cardTitle}>Alex</h4>
									<h6 className={classes.cardCategory}>@ALEXA</h6>
									<CardAvatar testimonial testimonialFooter>
										<a href='#pablo' onClick={(e) => e.preventDefault()}>
											<img src={cardProfile2Square} alt='...' />
										</a>
									</CardAvatar>
								</CardFooter>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
			{/* Testimonials 1 END */}
			{/* Testimonials 2 START */}
			<div
				className={
					classes.testimonials +
					' ' +
					// classes.sectionDark +
					// ' ' +
					classes.testimonial2
				}
			>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<Carousel {...settings} color='rose'>
								<div>
									<Card testimonial plain className={classes.card2}>
										<CardAvatar testimonial plain>
											<a href='#pablo' onClick={(e) => e.preventDefault()}>
												<img src={christian} alt='...' />
											</a>
										</CardAvatar>
										<CardBody plain>
											<h5 className={classes.cardDescription}>
												{'"'}Before your coaching services, I struggled to navigate dating
												and felt trapped between being aloof or too nice. Your coaching
												taught me to become a romantic lover while pursuing my own desires.
												The transformation was incredible and sustainable. I can now build
												deeper connections with women while moving forward at a pace that
												works for me. Your coaching has truly changed my dating life for the
												better.{'"'}
											</h5>
											<h4 className={classes.cardTitle}>Kyle T</h4>
										</CardBody>
										<div>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
										</div>
									</Card>
								</div>
								<div>
									<Card testimonial plain className={classes.card2}>
										<CardAvatar testimonial plain>
											<a href='#pablo' onClick={(e) => e.preventDefault()}>
												<img src={marc} alt='...' />
											</a>
										</CardAvatar>
										<CardBody plain>
											<h5 className={classes.cardDescription}>
												{'"'}Hector was the best investment I made in myself! He taught me
												how to unapologetically be myself, which has helped me in every
												aspect of my life. Hector has an intuition about people that is
												rarely wrong and as a mentor, he teaches you about yourself in a few
												weeks that would otherwise take years to learn. He tells you what
												you need to hear, not necessarily what you want to hear, and that
												helps you grow into a better person. I recommend everyone to learn
												from him, as the whole world can learn something from his wisdom.
												{'"'}
											</h5>
											<h4 className={classes.cardTitle}>Andre</h4>
										</CardBody>
										<div>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
										</div>
									</Card>
								</div>
								<div>
									<Card testimonial plain className={classes.card2}>
										<CardAvatar testimonial plain>
											<a href='#pablo' onClick={(e) => e.preventDefault()}>
												<img src={josue} alt='...' />
											</a>
										</CardAvatar>
										<CardBody plain>
											<h5 className={classes.cardDescription}>
												{'"'}Hector your the man, I&apos;ve always been pretty decent with
												my in person game but never had any luck with online dating apps. I
												bought your book &quot;what she really means&quot; not even half way
												through and I already have a new lady friend that I met on bumble
												and she can&apos;t get enough of me!{'"'}
											</h5>
											<h4 className={classes.cardTitle}>Josue Paz</h4>
										</CardBody>
										<div>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
											<Warning>
												<Star className={classes.starIcons} />
											</Warning>
										</div>
									</Card>
								</div>
							</Carousel>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
};

export default SectionTest;
