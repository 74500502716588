import React, { createContext } from 'react';
import { useState, useEffect } from 'react';

import {
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
	updateProfile,
	GoogleAuthProvider,
	signInWithPopup,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getUserDoc } from 'api/firebaseApi';
import PropTypes from 'prop-types';

const AuthContext = createContext({ user: null });

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({});

	useEffect(() => {
		onAuthStateChanged(auth, (userObject) => {
			getUserDoc(userObject?.uid).then(async () => {
				if (userObject?.uid) {
					const userDocRef = doc(db, 'users', userObject.uid);

					const userDocSnap = await getDoc(userDocRef);
					setUser({ ...userObject, ...userDocSnap.data() });
				} else {
					setUser(userObject);
				}
			});
		});
	}, []);

	const signInUser = async (email, password) => {
		try {
			await signInWithEmailAndPassword(auth, email, password);

			return true;
		} catch (error) {
			return { error: error.message };
		}
	};

	const updateProfileData = async ({
		displayName,
		displayDescription,
		photoURL,
	}) => {
		try {
			await updateProfile(auth.currentUser, {
				displayName,
				displayDescription,
				photoURL,
			});

			return true;
		} catch (error) {
			return { error: error.message };
		}
	};

	const signOutUser = async () => {
		try {
			await signOut(auth);
			setUser({});
			return true;
		} catch (error) {
			return false;
		}
	};

	const getUserData = async () => {
		const userDocRef = doc(db, 'users', user.uid);
		const userDocSnap = await getDoc(userDocRef);
		setUser((prevUser) => ({ ...prevUser, ...userDocSnap.data() }));
	};

	const googleProvider = new GoogleAuthProvider();

	const signInWithGoogle = async () => {
		try {
			const result = await signInWithPopup(auth, googleProvider);
			const user = result.user;

			const userDocRef = doc(db, 'users', user.uid);

			const userDocSnap = await getDoc(userDocRef);

			if (!userDocSnap.exists()) {
				await setDoc(
					userDocRef,
					{
						uid: user.uid,
						email: user.email,
						displayName: user.displayName,
						productsOwned: [],
					},
					{ merge: true }
				);
			}
		} catch (error) {
			// Handle the error here
			console.error('Error signing in with Google: ', error);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				signInUser,
				signOutUser,
				updateProfileData,
				signInWithGoogle,
				getUserData,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
