/*eslint-disable*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Favorite from '@material-ui/icons/Favorite';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import logo from 'assets/img/logo.png';
import SectionText from './Sections/SectionText.js';
import blogPostPageStyle from 'assets/jss/material-kit-pro-react/views/blogPostPageStyle.js';

const useStyles = makeStyles(blogPostPageStyle);

export default function BlogPostPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="info" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 300,
				}}
			/>
			<Parallax image={require('assets/img/simp.jpg')} filter="dark">
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem md={8} className={classes.textCenter}>
							<h1 className={classes.title}>My Dating & Relationship Philosophy</h1>
							<h2 className={classes.title}>Love. Lust. Respect.</h2>
							<h4 className={classes.subtitle}>
								I chose these words with great care after many years of struggling
								through the world of dating and relationships and finally breaking
								through the other side with most of the answers to any problems in that
								realm. I will explain why these three ideals provide the answer to any
								problem you may encounter in dating and relationships. If you master
								each of these, you will never struggle in any situation with a woman,
								whether it’s the first time meeting her, on a date, or married for
								twenty years. It’s very simple and all-encompassing, but it is difficult
								in application.
							</h4>
							<br />
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classes.main}>
				<div className={classes.container}>
					<SectionText />
				</div>
			</div>
			<Footer
				content={
					<div>
						<div className={classes.left}>
							<List className={classes.list}>
								<ListItem className={classes.inlineBlock}>
									<a href="/" target="_blank" className={classes.block}>
										About
									</a>
								</ListItem>

								<ListItem className={classes.inlineBlock}>
									<a href="/pricing" target="_blank" className={classes.block}>
										Pricing
									</a>
								</ListItem>
							</List>
						</div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a
								href="https://www.linkedin.com/in/sorin-gifei-214335106/"
								target="_blank"
							>
								Gifei Sorin
							</a>{' '}
							for a better web.
						</div>
					</div>
				}
			/>
		</div>
	);
}
