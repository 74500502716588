import React, { useContext } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Camera from '@material-ui/icons/Camera';
import People from '@material-ui/icons/People';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import NavPills from 'components/NavPills/NavPills.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Muted from 'components/Typography/Muted.js';
import Parallax from 'components/Parallax/Parallax.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Checkbox from '@material-ui/core/Checkbox';

import logo from 'assets/img/logo.png';
import defaultAvatar from 'assets/img/placeholder.jpg';
import Table from 'components/Table/Table.js';
import Check from '@material-ui/icons/Check';
import AuthContext from 'contexts/AuthContext';
import Footer from 'components/Footer/Footer.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/profilePageStyle.js';
import { getFile } from 'api/firebaseApi';
import Favorite from '@material-ui/icons/Favorite';
import wsrm from 'assets/img/wsrm.jpg';
import mge from 'assets/img/mge.png';
import ldlc from 'assets/img/ldlc.png';

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage() {
	const { user, getUserData } = useContext(AuthContext);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		getUserData();
	}, []);
	const classes = useStyles();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid
	);

	const downloadFile = async (product) => {
		try {
			const url = await getFile(product.fileUrl);
			window.open(url, '_blank');
		} catch (error) {
			window.open(product.fileUrl, '_blank');
		}
	};
	const formattedDate = (createdAt) => {
		const date = new Date(createdAt);
		return date.toLocaleDateString('en-US', {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		});
	};

	const imageMap = {
		mge: mge,
		wsrm: wsrm,
		ldlc: ldlc,
	};

	return (
		<div>
			<Header
				image={require('assets/img/logo.png')}
				brand={
					<img src={logo} alt="logo" style={{ width: 'auto', height: '4rem' }} />
				}
				links={<HeaderLinks dropdownHoverColor="rose" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 100,
				}}
			/>
			<Parallax
				image={require('assets/img/examples/city.jpg')}
				filter="dark"
				className={classes.parallax}
			/>

			<div
				className={classNames(
					classes.main,
					classes.mainRaised,
					classes.mainContainer
				)}
			>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={6}>
							<div className={classes.profile}>
								<div>
									<img src={defaultAvatar} alt="..." className={imageClasses} />
								</div>
								<div className={classes.name}>
									<h3 className={classes.title}>{user?.email}</h3>
								</div>
							</div>
						</GridItem>
					</GridContainer>
					<div className={classes.profileTabs}>
						<NavPills
							alignCenter
							color="primary"
							tabs={[
								{
									tabButton: 'Sessions',
									tabIcon: People,
									tabContent: (
										<Table
											striped
											tableHead={['#', '', 'Product Name', 'Date', 'Availability']}
											tableData={
												user?.productsOwned &&
												user?.productsOwned
													.filter((item) => item.isCoaching)
													.map((session, index) => [
														index + 1, // Session number
														session?.attended ? (
															<Checkbox
																key={81267378}
																checked={[1, 2, 3, 4, 5].indexOf(index + 1) !== -1}
																tabIndex={-1}
																onClick={() => {}}
																checkedIcon={<Check className={classes.checkedIcon} />}
																icon={<Check className={classes.uncheckedIcon} />}
																classes={{
																	checked: classes.checked,
																	root: classes.checkRoot,
																}}
															/>
														) : null,
														session.name,
														formattedDate(session.createdAt),

														'90 days',
													])
											}
											customCellClasses={[
												classes.textCenter,
												classes.padding0,
												classes.textRight,
												classes.textRight,
											]}
											customClassesForCells={[0, 1, 5, 6]}
											customHeadCellClasses={[
												classes.textCenter,
												classes.textRight,
												classes.textRight,
											]}
											customHeadClassesForCells={[0, 5, 6]}
										/>
									),
								},
								{
									tabButton: 'Products',
									tabIcon: Camera,
									tabContent: (
										<div>
											<GridContainer justify="center">
												{user?.productsOwned &&
													user?.productsOwned
														.filter((item) => !item.isCoaching)
														?.map((product, i) => (
															<GridItem xs={12} sm={12} md={6} key={i}>
																<Card
																	profile
																	plain
																	className={classes.card}
																	onClick={() => downloadFile(product)}
																>
																	<GridContainer>
																		<GridItem xs={12} sm={12} md={5}>
																			<CardHeader image plain>
																				<a href="#pablo">
																					{
																						<img
																							src={imageMap[product.id]}
																							alt="Product"
																							className={classes.smallerImage}
																						/>
																					}
																				</a>
																				<div
																					className={classes.coloredShadow}
																					style={{
																						backgroundImage: `url(${imageMap[product.id]})`,
																						opacity: '1',
																					}}
																				/>
																			</CardHeader>
																		</GridItem>
																		<GridItem xs={12} sm={12} md={7}>
																			<CardBody plain>
																				<h4 className={classes.cardTitle}>{product.name}</h4>
																				<Muted>
																					<h6>{product.model}</h6>
																				</Muted>
																				<p className={classes.description}>{product.description}</p>
																			</CardBody>
																		</GridItem>
																	</GridContainer>
																</Card>
															</GridItem>
														))}
											</GridContainer>
										</div>
									),
								},
							]}
						/>
					</div>

					<Clearfix />
				</div>
			</div>
			<Footer
				className={classes.footer}
				content={
					<div>
						<div className={classes.left}>
							<List className={classes.list}>
								<ListItem className={classes.inlineBlock}>
									<a
										href="/"
										// target="_blank"
										className={classes.block}
										rel="noopener noreferrer"
									>
										About
									</a>
								</ListItem>
								<ListItem className={classes.inlineBlock}>
									<a href="blog-post" className={classes.block}>
										My Philosophy
									</a>
								</ListItem>
								{/* <ListItem className={classes.inlineBlock}>
									<a
										href="https://www.creative-tim.com/license?ref=mkpr-login"
										target="_blank"
										className={classes.block}
										rel="noopener noreferrer"
									>
										Licenses
									</a>
								</ListItem> */}
							</List>
						</div>
						<div className={classes.right}>
							&copy; {1900 + new Date().getYear()} , made with{' '}
							<Favorite className={classes.icon} /> by{' '}
							<a href="/" target="_blank" rel="noopener noreferrer">
								Sorin Gifei
							</a>{' '}
							for a better web
						</div>
					</div>
				}
			/>
		</div>
	);
}
