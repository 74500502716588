import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import ViewDay from '@material-ui/icons/ViewDay';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import ArtTrack from '@material-ui/icons/ArtTrack';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';
import AuthContext from 'contexts/AuthContext';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
	// const easeInOutQuad = (t, b, c, d) => {
	//   t /= d / 2;
	//   if (t < 1) return (c / 2) * t * t + b;
	//   t--;
	//   return (-c / 2) * (t * (t - 2) - 1) + b;
	// };

	// const scrollGo = (element, to, duration) => {
	//   var start = element.scrollTop,
	//     change = to - start,
	//     currentTime = 0,
	//     increment = 20;

	//   var animateScroll = function () {
	//     currentTime += increment;
	//     var val = easeInOutQuad(currentTime, start, change, duration);
	//     element.scrollTop = val;
	//     if (currentTime < duration) {
	//       setTimeout(animateScroll, increment);
	//     }
	//   };
	//   animateScroll();
	// };

	const { dropdownHoverColor } = props;
	const classes = useStyles();

	const { user, signOutUser } = useContext(AuthContext);

	return (
		<List className={classes.list + ' ' + classes.mlAuto}>
			{/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              <LineStyle className={classes.dropdownIcons} /> Presentation Page
            </Link>,
            <Link to="/components" className={classes.dropdownLink}>
              <Layers className={classes.dropdownIcons} />
              All components
            </Link>,
            <a
              href="https://demos.creative-tim.com/material-kit-pro-react/#/documentation/tutorial?ref=mkpr-navbar"
              target="_blank"
              className={classes.dropdownLink}
              rel="noreferrer"
            >
              <Icon className={classes.dropdownIcons}>content_paste</Icon>
              Documentation
            </a>,
          ]}
        />
      </ListItem> */}
			{/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Sections"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={ViewDay}
          dropdownList={[
            <Link
              to="/sections#headers"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              <Dns className={classes.dropdownIcons} /> Headers
            </Link>,
            <Link
              to="/sections#features"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "features")}
            >
              <Build className={classes.dropdownIcons} /> Features
            </Link>,
            <Link
              to="/sections#blogs"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "blogs")}
            >
              <ListIcon className={classes.dropdownIcons} /> Blogs
            </Link>,
            <Link
              to="/sections#teams"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "teams")}
            >
              <People className={classes.dropdownIcons} /> Teams
            </Link>,
            <Link
              to="/sections#projects"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "projects")}
            >
              <Assignment className={classes.dropdownIcons} /> Projects
            </Link>,
            <Link
              to="/sections#pricing"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "pricing")}
            >
              <MonetizationOn className={classes.dropdownIcons} /> Pricing
            </Link>,
            <Link
              to="/sections#testimonials"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "testimonials")}
            >
              <Chat className={classes.dropdownIcons} /> Testimonials
            </Link>,
            <Link
              to="/sections#contacts"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "contacts")}
            >
              <Call className={classes.dropdownIcons} /> Contacts
            </Link>,
          ]}
        />
      </ListItem> */}
			<ListItem className={classes.listItem}>
				<Link
					to='/landing-page'
					key='/landing-page'
					className={classes.dropdownLink}
					style={{
						color: 'inherit',
						margin: '0px',
						display: 'inline-flex',
						padding: '0.9375rem 0rem',
						position: 'relative',
						fontSize: '12px',
						fontWeight: '400',
						lineHeight: '20px',
						textTransform: 'uppercase',
						textDecoration: 'none',
					}}
				>
					<ViewCarousel
						href='#pablo'
						onClick={(e) => e.preventDefault()}
						color='transparent'
						hoverColor={dropdownHoverColor}
						className={classes.dropdownLink}
					/>{' '}
					Home
				</Link>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Link
					to='/pricing'
					key='/pricing'
					className={classes.dropdownLink}
					style={{
						color: 'inherit',
						margin: '0px',
						display: 'inline-flex',
						padding: '0.9375rem 4rem',
						position: 'relative',
						fontSize: '12px',
						fontWeight: '400',
						lineHeight: '20px',
						textTransform: 'uppercase',
						textDecoration: 'none',
					}}
				>
					<ShoppingCart
						href='#pablo'
						onClick={(e) => e.preventDefault()}
						color='transparent'
						className={classes.dropdownLink}
						hoverColor={dropdownHoverColor}
						buttonProps={{
							className: classes.navLink,
							color: 'transparent',
						}}
					/>{' '}
					Coaching
				</Link>
			</ListItem>
			<ListItem className={classes.listItem}>
				<CustomDropdown
					noLiPadding
					navDropdown
					hoverColor={dropdownHoverColor}
					style={{
						padding: '0.9375rem 3rem',
					}}
					buttonText='Products'
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					buttonIcon={ShoppingCart}
					dropdownList={[
						<Link
							to='/what-she-really-means'
							key='/what-she-really-means'
							className={classes.dropdownLink}
						>
							<ShoppingCart className={classes.dropdownIcons} /> What She Really Means
						</Link>,
						<Link
							to='/meet-girls-everywhere'
							key='/meet-girls-everywhere'
							className={classes.dropdownLink}
						>
							<ShoppingCart className={classes.dropdownIcons} /> Meet Girls Everywhere
						</Link>,
						// <Link
						// 	to='/locations-dlc'
						// 	key='/locations-dlc'
						// 	className={classes.dropdownLink}
						// >
						// 	<ShoppingCart className={classes.dropdownIcons} /> Locations - DLC
						// </Link>,
					]}
				/>
			</ListItem>
			<ListItem className={classes.listItem}>
				{user?.uid ? (
					<Button
						color={'white'}
						target='_blank'
						className={classes.navButton}
						round
						onClick={() => signOutUser()}
					>
						logout
					</Button>
				) : (
					<Link to='/login-page'>
						<Button color={'white'} className={classes.navButton} round>
							login
						</Button>
					</Link>
				)}
				{user?.uid ? (
					<Link to='/profile-page'>
						<Button color={'white'} className={classes.navButton} round>
							profile
						</Button>
					</Link>
				) : (
					<Link to='/signup-page'>
						<Button color={'white'} className={classes.navButton} round>
							Signup
						</Button>
					</Link>
				)}
			</ListItem>
		</List>
	);
}

HeaderLinks.defaultProps = {
	hoverColor: 'primary',
};

HeaderLinks.propTypes = {
	dropdownHoverColor: PropTypes.oneOf([
		'dark',
		'primary',
		'info',
		'success',
		'warning',
		'danger',
		'rose',
	]),
};
