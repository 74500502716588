import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';
import { AuthProvider } from 'contexts/AuthContext';

import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

import 'assets/scss/material-kit-pro-react.scss?v=1.9.0';
import './index.css';
import 'rodal/lib/rodal.css';

import BlogPostPage from 'views/BlogPostPage/BlogPostPage.js';
import ComponentsPage from 'views/ComponentsPage/ComponentsPage.js';
import DashboardPage from 'views/ProductPage/DashboardPage.js';
import LandingPage from 'views/LandingPage/LandingPage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import PricingPage from 'views/PricingPage/PricingPage.js';
import ProfilePage from 'views/ProfilePage/ProfilePage.js';
import ProductPage from 'views/ProductPage/ProductPage.js';
import ProductPage2 from 'views/ProductPage/ProductPage2';
import ProductPage3 from 'views/ProductPage/ProductPage3';
import SectionsPage from 'views/SectionsPage/SectionsPage.js';
import SignUpPage from 'views/SignUpPage/SignUpPage.js';
import ErrorPage from 'views/ProfilePage/ErrorPage.js';
import SuccessPage from 'views/ProfilePage/SuccessPage';
import SettingsPage from 'views/ProfilePage/SettingsPage';
import toast, { Toaster } from 'react-hot-toast';
import PrivateRoute from 'contexts/PrivateRoute';
import AnonRoute from 'contexts/AnonRoute';

var hist = createBrowserHistory();

const notify = (text) => toast(text);

const App = () => {
	return (
		<>
			<Toaster position='bottom-center' />
			<Router history={hist}>
				<Switch>
					<Route path='/blog-post' component={BlogPostPage} />
					<Route path='/components' component={ComponentsPage} />
					<Route path='/sections' component={SectionsPage} />
					<AnonRoute notify={notify} path='/login-page' component={LoginPage} />
					<AnonRoute notify={notify} path='/signup-page' component={SignUpPage} />
					<PrivateRoute path='/profile-page' component={ProfilePage} />
					<PrivateRoute path='/settings' component={SettingsPage} />
					<PrivateRoute path='/error' component={ErrorPage} />
					<PrivateRoute path='/success' component={SuccessPage} />
					<Route path='/what-she-really-means' component={ProductPage} />
					<Route path='/meet-girls-everywhere' component={ProductPage2} />
					{/* <Route path="/locations-dlc" component={ProductPage3} /> */}
					<PrivateRoute path='/dashboard' component={DashboardPage} />
					<Route path='/pricing' component={PricingPage} />
					<Route path='/' component={LandingPage} />
				</Switch>
			</Router>
		</>
	);
};

ReactDOM.render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById('root')
);
